.App {
  text-align: center;
}

.josette-header {
  /* background-color: rgba(255, 192, 203, 0.1); */
  background-color: rgba(254, 249, 250)
}

.josette-main-title {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 62px;
  z-index: 1;
}

.josette-welcome-title {
  font-size: 3em;
  line-height: 1em;
  font-family: Helvetica, sans-serif;
  font-weight: bold;
  /* margin-bottom: 20px; */
  border-left: 2px solid pink;
}

.josette-main-tagline {
  font-size: 1.3em;
  font-family: Helvetica, sans-serif;
  font-weight: bold;
}

.josette-tagline-details {
  font-size: 1.3em;
  font-family: Helvetica, sans-serif;
}

.josette-main-section {
  /*height: 65vh;*/
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: pink;
  /* margin-bottom: 20px; */
  /* padding-top: 100px;
  padding-bottom: 100px; */
  height: 540px;
}

.typewriter-effect {
  font-size: 20px;
}

.search-header {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0 ,0 ,0 , 0.1);
  width: 100%;
  max-width: 1000px;
  /* max-width: 80%; */
}

.search-box {
  margin-top: 20px;
  margin-bottom: 10px;
  position: relative;
}

/*input[type="text"] {
  /* width: cal(100% - 40px); */
textarea {
  width: 100%;
  padding: 10px;
  padding-right: 50px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  resize: none !important;
}

.search-button {
  position: absolute;
  right: 5px;
  bottom: 11px;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 192, 203, 0.5);
}


.search-button-existing-legacy {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 15px;
  bottom: 13px;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
  border-radius: 50%;
  background-color: rgba(255, 192, 203, 0.5);
}


.search-button-existing {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 15px;
  bottom: 13px;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
  border-radius: 50%;
  background-color: rgba(255, 192, 203, 0.5);
}

.search-box-existing input:disabled {
  background-color: white;
}
.suggestions button {
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  font-size: 18px;
  background-color: rgba(255, 192, 203, 0.1);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: left;
}

.search-box-existing {
  width: 100%;
  /* padding: 10px; */
  padding-right: 50px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0px;
}

.search-box-existing input {
  width: 100%;
  height: 100%;
  border: none;
  font-size: 16px;
}

.search-box-existing input:focus {
  outline: none;
}

/*
.search-box-existing button {
  position: absolute;
  height: 50%;
  right: 10px;
  bottom: 10px;
  border: none;
  background: none;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid green;
}
*/

.icon-button {
  font-size: 20px;
  margin-right: 5px;
  margin-left: 5px;
}

.new-question{
  margin-bottom: 50px;
}

.admin-section-title {
  padding-top: 56px;
  padding-bottom: 5px;
  margin-bottom: 20px;
  font-size: 1.2em;
  font-weight: bold;
  border-bottom: 1px solid pink;
}

.admin-user-message {
  text-align: left;
}

.admin-josette-message {
  text-align: left;
}

.privacy-policy-title {
  font-size: 1.2em;
  font-weight: bold;
  text-align: left;
}

.table td {
  vertical-align: middle;
}

.pageloader-loading {
  font-size: 20px;
}

.pageloader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh;
}

/* Changes added after ChatGPT recos */

/*
.josette-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
}

html, body {
  height: 100%;
  margin: 0;
  border: 1px solid green;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid orange;
}
*/

/*
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
}
*/

.josette-welcome-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 92vh; */
}

footer {
  /* height: 8vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-links {
  color: black;
  text-decoration: none;
  margin: 0 10px;
}

@media (max-width: 576px) {

  .josette-welcome-title {
    font-size: 3em;
    line-height: 1em;
    font-family: Helvetica, sans-serif;
    font-weight: bold;
    /* margin-bottom: 20px; */
    border-left: 4px solid pink;
  }

  .josette-welcome-page {
    height: auto;
    min-height: 100vh;
  }

  .suggestions button {
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    font-size: 16px;
    background-color: rgba(255, 192, 203, 0.1);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: left;
  }

  footer {
    font-size: 14px;
    /* padding-bottom: 100px; */
  }

}

.suggestion-button {
  display: flex;
  align-items: center;
  padding: 10px;
  border: none;
  background-color: rgba(255, 192, 203, 0.1);
  color: black;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
}

.icon-button {
  margin-right: 8px;
}

.text-button {
  display: flex;
  align-items: center;
}

.suggestion-button:hover {
  background-color: rgba(255, 192, 203, 0.2);
  border: solid 1px #ccc;
}

@media (min-width: 577px) {
  .datetime-table-column {
    width: 150px;
  }
}

.text-summary-conversation {
  text-align: left;
}

/*
.table-class-experiment th,
.table-class-experiment td {
  display: block;
  box-sizing: border-box;
  width: 100%;
}

.table-class-experiment tr {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.table-class-experiment tr td {
  border: 1px solid #dee2e6;
  padding: 0.5rem;
}
*/

.align-top {
  vertical-align: top !important;
}

.admin-stats-element {
  text-align: left;
}

.admin-stats-number {
  font-weight: bold;
}

.user-message-conversation-legacy {
  /* background-color: rgba(254, 249, 250); */
  /* color: #551A8B; */
  background-color:rgba(255, 192, 203, 0.4);
  padding: 6px 8px;
  border-radius: 15px;
  display: inline-block;
  text-align: left;
  font-weight: bold;
}


.user-message-conversation {
  /* background-color: rgba(254, 249, 250); */
  /* color: #551A8B; */
  background-color:rgba(255, 192, 203, 0.25);
  /* padding: 6px 8px; */
  padding: 6px 10px;
  border-radius: 20px;
  display: inline-block;
  text-align: left;
  font-weight: bold;
}

.add-padding-to-answer {
  padding-left: 10px;
  padding-right: 10px;
}

.no-padding-left {
  padding-left: 5px !important;
}

.add-padding-left {
  padding-left: calc(var(--bs-gutter-x)* .5 - 5px)!important;
}

.blurred-section {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 40px;
  backdrop-filter: blur(10px) !important;
  /* background-color: rgba(255, 192, 203, 0.6) !important; */
  z-index: 0;
}

.follow-up-question {
  font-size: 0.8em;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid pink;
  background-color: white;
  color: black;
}

.follow-up-question:hover {
  background-color:rgba(255, 192, 203, 0.25);
}

.refresh-follow-up-questions {
  cursor: pointer;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: transparent;
  border: 1px solid black;
}

.refresh-follow-up-questions:hover {
  background-color:rgba(255, 192, 203, 0.25);
}

/* For the blog posts */

.blog {

}

.blogpost-title {
  font-size: 1.5em;
  font-weight: bold;
}

.blogpost-date {
  font-weight: lighter;
  font-size: 0.8em;
}

.blogpost-content {
}

.blogpost-subtitle {
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 15px;
}

.deeptechs-guest{
  font-weight: bold;
  text-decoration: none;
  color: inherit;
}

.deeptechs-guest:hover {
  color: pink;
}

.book-recommendation {
  font-weight: bold;
  text-decoration: none;
  color: inherit;
  border-bottom: 1px solid pink;
}

.book-recommendation:hover {
  color: pink;
}

#language-switcher-dropdown {
  text-decoration: none;
}

/*
.josette-customers-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  width: 100%;
  position: relative;
  height: 80px;
  gap: 150px;
}
*/

.josette-customers-row {
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.logo-track {
  display: flex;
  animation: scroll 60s linear infinite;
  /* gap: 150px; */
  width: max-content;
}

@media (max-width: 576px) {
  .josette-customers-logo {
    max-width: 80px;
    max-height: 50px;
    object-fit: contain;
    /* margin: 0 10px; */
    /* margin: 0 75px; */
    margin-right: 80px;
  }
}

@media (min-width: 577px) {
  .josette-customers-logo {
    max-width: 100px;
    max-height: 60px;
    object-fit: contain;
    /* margin: 0 10px; */
    /* margin: 0 75px; */
    margin-right: 150px;
  }
}

/*
@keyframes scroll {
  from {
    transform: translateX(5%);
  }
  to {
    transform: translateX(-100%);
  }
}
  */